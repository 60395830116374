import { ListItemIcon, ListItemText, ListItem, List } from "@material-ui/core";
import { useMemo } from "react";
import { adminMenu, userMenu } from "./menu-list-types";

type OwnProps = {
  setComponent: (component: JSX.Element) => void;
  disabled?: boolean;
  isAdmin: boolean;
};

// const adminMenu = [
//   {
//     name: "Kalender",
//     component: <Calendar />,
//     icon: EventOutlinedIcon,
//   },
//   {
//     name: "Brugere",
//     component: <User />,
//     icon: PeopleOutlineOutlinedIcon,
//   },
// ];

// const userMenu = [
//   {
//     name: "Kalender",
//     component: <Calendar />,
//     icon: EventOutlinedIcon,
//   },
// ];

export const MenuList = (props: OwnProps) => {
  const { disabled, setComponent, isAdmin } = props;

  const items = useMemo(() => {
    return isAdmin ? adminMenu : userMenu;
  }, [isAdmin]);

  return (
    <List>
      {items.map((item, index) => (
        <ListItem
          button
          onClick={() => {
            setComponent(<item.component />);
          }}
          key={item.name}
          disabled={disabled}
        >
          <ListItemIcon style={{ color: "#fff" }}>
            <item.icon />
          </ListItemIcon>
          <ListItemText primary={item.name} />
        </ListItem>
      ))}
    </List>
  );
};
