import { LoginInfo } from '../../../../components/dashboard/dashboard-types';
import { UserDto } from '../../../model';
import { getHttpClient } from '../../../utils';

const getUsersUrl = `user/read.php`;
const getUserUrl = `user/read_one.php`;
const createUserUrl = `user/create.php`;
const updateUserUrl = `user/update.php`;
const deleteUserUrl = `user/delete.php`;

export const users = {
    getUsersAsync: async () => getHttpClient().getAsync<UserDto[]>(getUsersUrl),
    getUserAsync:  async (email:string) => getHttpClient().getAsync<UserDto>(getUserUrl, {email}),
    createUserAsync: async (user: LoginInfo) => getHttpClient().postAsync<LoginInfo>(createUserUrl, undefined, user),
    updateUserAsync: async (user: UserDto) => getHttpClient().putAsync(updateUserUrl, undefined, user),
    deleteUserAsync: async (id: string|number|undefined) => getHttpClient().deleteAsync(deleteUserUrl, {id}),  
} 




