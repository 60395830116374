
import { serverClient } from "../../../../../backend/client/domains/server-client";

import {call, put, all, fork, takeEvery} from "typed-redux-saga";
import { actions, ActionTypes } from "../../store/reservation-store/reservation-store";
import {
    AppointmentModel,    
  } from "@devexpress/dx-react-scheduler";

function* fetchReservations() {       
        const responseReservations = yield* call(serverClient.reservations.getReservationsAsync);            
        
        if(responseReservations.ok ){
               
            const reservations = responseReservations.data.map((x, key) => (
                {
                    key: key,
                    title:x.title, 
                    id:x.id, 
                    startDate: new Date(x.startDate),
                    endDate: new Date(x.endDate), 
                    conferenceroomId:x.conferenceroomId,
                    user:x.user,
                    allDay:x.allDay,
                    rRule:x.rRule,
                    notes:x.notes
                })            
            )            

            yield* put(actions.fetchReservationsSucces(reservations));
        }else{
            yield* put(actions.fetchReservationsFailure());
        }        
 
}

function* fetchResources() {       
       
    const responseResource =  yield* call(serverClient.conferenceRooms.getConferenceRoomsAsync);       
    
    if(responseResource.ok){
        const data = responseResource.data.map((x) => ({id:x.id, text:x.text, color:x.color})); 
        const resources = [{
            fieldName: "conferenceroomId",
            title: "Mødelokale",
            instances: data,
        }]       

        yield* put(actions.fetchResourceSucces(resources));
    }else{
        yield* put(actions.fetchResourceFailure());
    }        

}

function* createReservation(action:  ReturnType<typeof actions.createReservationsRequest>) {    
    const result = yield* call(serverClient.reservations.createReservationAsync, action.payload.addedReservation);
    if(result.ok){
        const createdData = result.data;        
        const reservations:AppointmentModel[] = [...action.payload.reservations, {...createdData, startDate: new Date(createdData.startDate), endDate:new Date(createdData.endDate)}];        
        yield* put(actions.createReservationsSucces(reservations));
    }else{
        yield* put(actions.createReservationsFailure(result.error));
    }    
}


function* updateReservation(action:  ReturnType<typeof actions.updateReservationsRequest>) {  
    const result = yield* call(serverClient.reservations.updateReservationAsync, action.payload.updatedReservation);
    if(result.ok){
        const createdData = result.data;               
        const reservations:AppointmentModel[] = action.payload.reservations.map((appointment) => (
            (createdData.id === appointment.id) ? {...appointment, ...createdData} : appointment 
        ));   
        yield* put(actions.updateReservationsSucces(reservations));
    }else{
        yield* put(actions.updateReservationsFailure(result.error));
    }    
}


function* deleteReservation(action: ReturnType<typeof actions.deleteReservationsRequest>){
    const result = yield* call(serverClient.reservations.deleteReservationAsync, action.payload.id);
    if(result.ok){
        const reservations = action.payload.reservations.filter((x) => x.id !== action.payload.id);
        yield* put(actions.deleteReservationsSuccess(reservations));
    }else{
        yield* put(actions.deleteReservationsFail(result.error))
    }
}

function* watchUpdateReservation(){
    yield* takeEvery(ActionTypes.UPDATE_RESERVATOIN_REQUEST, updateReservation);
}

function* watchDeleteReservation(){
    yield* takeEvery(ActionTypes.DELETE_RESERVATION_REQUEST, deleteReservation);
}

function* watchCreateReservation(){
    yield* takeEvery(ActionTypes.CREATE_RESERVATION_REQUEST, createReservation);
}

function* watchFetchReservations(){
    yield* takeEvery(ActionTypes.FETCH_RESERVATION_REQUEST, fetchReservations);
}

function* watchFetchResources(){
    yield* takeEvery(ActionTypes.FETCH_RESOURCE_REQUEST, fetchResources);
}

export function* reservationsSaga() {
    yield all([fork(watchFetchReservations), fork(watchFetchResources), fork(watchCreateReservation), fork(watchDeleteReservation), fork(watchUpdateReservation)]);
  } 