import { conferenceRoomDto } from '../../../model/conference-room';
import { getHttpClient } from '../../../utils';

const getConferenceRoomsUrl = `conferenceroom/read.php`;
const getConferenceRoomUrl = `conferenceroom/read_one.php`;
const createConferenceRoomUrl = `conferenceroom/create.php`;
const updateConferenceRoomUrl = `conferenceroom/update.php`;
const deleteConferenceRoomUrl = `conferenceroom/update.php`;

export const conferenceRooms = {
    getConferenceRoomsAsync: async () => getHttpClient().getAsync<conferenceRoomDto[]>(getConferenceRoomsUrl),
    getConferenceRoomAsync:  async (id:number) => getHttpClient().getAsync<conferenceRoomDto>(getConferenceRoomUrl,{id}),
    createConferenceRoomAsync: async (room: conferenceRoomDto) => getHttpClient().postAsync(createConferenceRoomUrl, undefined, room),
    updateConferenceRoomAsync: async (room: conferenceRoomDto) => getHttpClient().putAsync(updateConferenceRoomUrl, undefined, room),
    deleteConferenceRoomAsync: async (id: number) => getHttpClient().deleteAsync(deleteConferenceRoomUrl, {id}),  
} 




