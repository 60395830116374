import { Reducer } from "redux";
import { action as actionCreator, ActionType } from "typesafe-actions";
import { LoginInfo, menuItems } from "../../../../../components/dashboard/dashboard-types";
import { Loading, loadingFailed, loadingHasFinished, loadingIsLoading, loadingNotStarted } from "../../../../../utils";


export enum ActionTypes{
    FETCH_USERS_REQUEST = "FETCH_USER_REQUEST",
    FETCH_USERS_SUCCESS = "FETCH_USER_SUCCESS",
    FETCH_USERS_FAILURE = "FETCH_USER_FAILURE",
    
    GET_USER_REQUEST = "GET_USER_REQUEST",
    GET_USER_SUCCESS = "GET_USER_SUCCESS",
    GET_USER_FAILURE = "GET_USER_FAILURE",

    DELETE_USER_REQUEST = "DELETE_USER_REQUEST",
    DELETE_USER_SUCCES = "DELETE_USER_SUCCES",
    DELETE_USER_FAIL = "DELETE_USER_FAIL",
    
    SET_TOKEN_REQUEST = "SET_TOKEN_REQUEST",
    SET_TOKEN_SUCCESS = "SET_TOKEN_SUCCESS",
    SET_TOKEN_FAILURE = "SET_TOKEN_FAILURE",

    REMOVE_TOKEN_REQUEST = "REMOVE_TOKEN_REQUEST",
    REMOVE_TOKEN_SUCCESS = "REMOVE_TOKEN_SUCCESS",
    REMOVE_TOKEN_FAILURE = "REMOVE_TOKEN_FAILURE",

    CREATE_USER_REQUEST = "CREATE_USER_REQUEST",
    CREATE_USER_SUCCES = "CREATE_USER_SUCCES",
    CREATE_USER_FAIL = "CREATE_USER_FAIL",
    
    CLEAR_USER = "CLEAR_USER",
}

export const actions = {
    fetchtUsersRequest: () => actionCreator(ActionTypes.FETCH_USERS_REQUEST),
    fetchUsersSucces: (users: LoginInfo[]) => actionCreator(ActionTypes.FETCH_USERS_SUCCESS, {users}),
    fetchUsersFailure: (error:string) => actionCreator(ActionTypes.FETCH_USERS_FAILURE,{error}),
    
    getUserRequest: (email:string) => actionCreator(ActionTypes.GET_USER_REQUEST, {email}),
    getUserSucces: (user: LoginInfo) => actionCreator(ActionTypes.GET_USER_SUCCESS, {user}),
    getUserFailure: () => actionCreator(ActionTypes.GET_USER_FAILURE),

    createUserRequest: (users:LoginInfo[], addedUser:LoginInfo) => actionCreator(ActionTypes.CREATE_USER_REQUEST, {users, addedUser}),
    createUserSuccess: (users:LoginInfo[]) => actionCreator(ActionTypes.CREATE_USER_SUCCES, { users }),
    createUserFail: (error: string) => actionCreator(ActionTypes.CREATE_USER_FAIL, {error}),

    deleteUserRequest: (users:LoginInfo[], id:string|number|undefined) => actionCreator(ActionTypes.DELETE_USER_REQUEST, {users, id}),
    deleteUserSuccess: (users:LoginInfo[]) => actionCreator(ActionTypes.DELETE_USER_SUCCES, { users }),
    deleteUserFail: (error: string) => actionCreator(ActionTypes.DELETE_USER_FAIL, {error}),
    
    setTokenRequest: (token: string, email: string, photoUrl: string) => actionCreator(ActionTypes.SET_TOKEN_REQUEST, {token, email, photoUrl}),
    setTokenSucces:  (token:string, email:string, photoUrl: string) => actionCreator(ActionTypes.SET_TOKEN_SUCCESS, {token, email, photoUrl}),  
    setTokenFailure: () => actionCreator(ActionTypes.SET_TOKEN_FAILURE),

    removeTokenRequest: () => actionCreator(ActionTypes.REMOVE_TOKEN_REQUEST),
    removeTokenSucces:  () => actionCreator(ActionTypes.REMOVE_TOKEN_SUCCESS),  
    removeTokenFailure: () => actionCreator(ActionTypes.REMOVE_TOKEN_FAILURE),
    
    clearUsers: () => actionCreator(ActionTypes.CLEAR_USER),   
}

type UsersState = {
    loading: Loading<{users:LoginInfo[]}>;
    loadingUser: Loading<{user: LoginInfo}>;
    menuItems: menuItems[];
    token: string | null;
    email: string | null;
    photoUrl: string | null;
    error:string; 
}

const initialState: UsersState = {
    loading: loadingNotStarted,
    loadingUser: loadingNotStarted,
    menuItems: [],
    token: sessionStorage.getItem("TOKEN"),
    email: sessionStorage.getItem("EMAIL"),
    photoUrl: sessionStorage.getItem("PHOTO"),
    error:"",
};

export const userReducer: Reducer<UsersState, ActionType<typeof actions>> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_USERS_REQUEST:
        case ActionTypes.CREATE_USER_REQUEST:
        case ActionTypes.DELETE_USER_REQUEST:
           return {...state, loading: loadingIsLoading };
        case ActionTypes.FETCH_USERS_SUCCESS:
        case ActionTypes.CREATE_USER_SUCCES:
        case ActionTypes.DELETE_USER_SUCCES:
            return {...state, loading: loadingHasFinished({users: action.payload.users})}
        case ActionTypes.FETCH_USERS_FAILURE:
        case ActionTypes.CREATE_USER_FAIL:
        case ActionTypes.DELETE_USER_FAIL:
            return{...state, loading: loadingFailed, error: action.payload.error};

        case ActionTypes.GET_USER_REQUEST:
            return{...state, loadingUser: loadingIsLoading}
        case ActionTypes.GET_USER_SUCCESS:
            return {...state, loadingUser: loadingHasFinished({user: action.payload.user})}
        case ActionTypes.GET_USER_FAILURE:
            return{...state, loadingUser: loadingFailed}

        case ActionTypes.SET_TOKEN_SUCCESS:
            return {...state, token: action.payload.token, email: action.payload.email, photoUrl: action.payload.photoUrl}

        case ActionTypes.REMOVE_TOKEN_SUCCESS:
            return {...state, token: null, email: null, photoUrl: null}
            
        case ActionTypes.CLEAR_USER:{
            return {...state, loading: initialState.loading, loadingUser: initialState.loadingUser};
        }
            

        default:
            return{...state};
    }
};