import { Maybe } from "./type-utils";

export const hasValue = <T>(value: Maybe<T>): value is T => value !== undefined && value != null;
export const isUndefinedOrNull = <T>(value: Maybe<T>): value is undefined | null => !hasValue(value);

interface IHasValueAndNotEmpty {
  (value: Maybe<string>): value is string;
  (value: any): boolean;
}
export const hasValueAndNotEmpty = ((value: any) => hasValue(value) && value !== "" && (!Array.isArray(value) || value.length !== 0)) as IHasValueAndNotEmpty;

interface IIsUndefinedOrNullOrEmpty {
  (value: Maybe<string>): value is null | undefined | "";
  (value: any): boolean;
}
export const isUndefinedOrNullOrEmpty = ((value: any) => !hasValueAndNotEmpty(value)) as IIsUndefinedOrNullOrEmpty;

export const hasValueAndNotNaNAndNotInfinity = (value: Maybe<number>): value is number => hasValue(value) && Number.isFinite(value);

export const isUndefinedOrNullOrNaNOrInfinity = (value: Maybe<number>) => !hasValueAndNotNaNAndNotInfinity(value);

export const nullToUndefined = <T>(value: Maybe<T>): T | undefined => (value === null ? undefined : value);

export type SortDirection = "ascending" | "descending";

type ComparablePrimitiveValue = string | number | Date | boolean | undefined | null | { toString(): string };
export type ComparableValue = ComparablePrimitiveValue | ComparablePrimitiveValue[];

export const compare = <T extends ComparableValue>(value1: T, value2: T, direction: SortDirection): number => {
  const { v1, v2 } = direction === "ascending" ? { v1: value1, v2: value2 } : { v1: value2, v2: value1 };
  // hvis vi herefter kalder compare rekursivt med v1 og v2 skal vi kalde med direction=ascending så vi ikke risikerer at bytte rundt på v1 og v2 igen

  if (isUndefinedOrNull(v1) || isUndefinedOrNull(v2)) {
    if (hasValue(v1)) return 1;
    if (hasValue(v2)) return -1;
    return 0;
  }

  if (typeof v1 === "number" && typeof v2 === "number") {
    return v1 - v2;
  }

  if (typeof v1 === "string" && typeof v2 === "string") {
    return v1.trim().localeCompare(v2.trim());
  }

  if (typeof v1 === "boolean" && typeof v2 === "boolean") {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  if (v1 instanceof Date && v2 instanceof Date) {
    return compare(v1.valueOf(), v2.valueOf(), "ascending");
  }

  if (v1 instanceof Array && v2 instanceof Array) {
    const maxArrayLength = v1.length > v2.length ? v1.length : v2.length;
    for (let i = 0; i < maxArrayLength; i++) {
      const result = compare(v1[i], v2[i], "ascending");
      if (result !== 0) return result;
    }
    return compare(v1.length, v2.length, "ascending");
  }

  if (hasValue(v1.toString) && hasValue(v2.toString)) {
    return compare(v1.toString(), v2.toString(), "ascending");
  }

  if (v1 < v2) {
    return -1;
  }
  if (v1 > v2) {
    return 1;
  }
  return 0;
};
