import {
  AppointmentForm,
  TodayButton,
} from "@devexpress/dx-react-scheduler-material-ui";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterMoment from "@mui/lab/AdapterMoment";
import "moment/locale/da";

export const DateEditor = (props: AppointmentForm.DateEditorProps) => {
  const { excludeTime, ...rest } = props;
  const dateFormat = excludeTime ? "DD-MM-YYYY" : "DD-MM-YYYY HH:mm";

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <AppointmentForm.DateEditor
        {...rest}
        excludeTime={excludeTime}
        format={dateFormat}
        ampm={false}
      />
    </LocalizationProvider>
  );
};

export const TodayButtonEdit = (props: TodayButton.ButtonProps) => {
  return <TodayButton.Button {...props} getMessage={() => "I dag"} />;
};
