import { createReserVationDto, reservationDto } from '../../../model/reservation'
import { getHttpClient } from '../../../utils';

const getReservationsUrl = `reservation/read.php`;
const getReservationUrl = `reservation/read_one.php`;
const createReservationUrl = `reservation/create.php`;
const updateReservationUrl = `reservation/update.php`;
const deleteReservationUrl = `reservation/delete.php`;

export const reservations = {
    getReservationsAsync: async () => await getHttpClient().getAsync<reservationDto[]>(getReservationsUrl),    
    getReservationAsync:  async (id:number) => await getHttpClient().getAsync<reservationDto>(getReservationUrl,{id}),
    createReservationAsync: async (reservation: createReserVationDto) => await getHttpClient().postAsync<reservationDto>(createReservationUrl, undefined, reservation),
    updateReservationAsync: async (reservation: reservationDto) => await getHttpClient().putAsync<reservationDto>(updateReservationUrl, undefined, reservation),
    deleteReservationAsync: async (id: number|string|undefined) => await getHttpClient().deleteAsync(deleteReservationUrl, {id}),  
} 






   