import { makeStyles } from "@material-ui/styles";

export function dashBoardStyles(){
    return makeStyles(() => ({        
      header: {
          backgroundColor: "#9E8D74",
          color: "#fff",
          borderBottom: "1px solid hsl(210, 32%, 93%)",
        },
        collapseBtn: {
          color: "#fff",
          minWidth: 0,
          width: 40,
          borderRadius: "50%",
          border: "none",
          backgroundColor: "rgba(0,0,0,0.24)",
          margin: "0 auto 16px",
          "&:hover": {
            backgroundColor: "rgba(0,0,0,0.38)",
          },
        },
        sidebar: {
          backgroundColor: "#323E31",
          border: "none",
          color: "#fff",          
        },
        content: {
          backgroundColor: "#f9f9f9",
        },
      }));
} 