import moment from "moment";

import { hasValue, isUndefinedOrNullOrEmpty, isUndefinedOrNull } from "./object-utils";
import { Maybe } from "./type-utils";

/**
 * Konverterer en Date til kl 00:00 lokal tid
 */
interface IStartOfDay {
  (date: Date): Date;
  (date: Date | null): Date | null;
  (date: Date | undefined): Date | undefined;
  (date: Maybe<Date>): Maybe<Date>;
}
export const startOfDay = ((date: Maybe<Date>): Maybe<Date> => (hasValue(date) ? moment(date).startOf("day").toDate() : date)) as IStartOfDay;

/**
 * Giver en Date med dags dato kl. 00:00 lokal tid
 */
export const today = (): Date => moment().startOf("day").toDate();

/**
 * Giver en Date med dags dato og det aktuelle klokkeslet
 */
export const now = (): Date => moment().toDate();

export type DateFormat = "d" | "t" | "ts" | "dt" | "dts" | "iso8601_local" | "iso8601_utc" |"mySqlDate";

export const dateFormatStrings: { [key in DateFormat]: string } = {
  d: "L",
  t: "LT",
  ts: "LTS",
  dt: "L LT",
  dts: "L LTS",
  iso8601_local: "YYYY-MM-DD[T]HH:mm:ss.SSSZ",
  iso8601_utc: "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]",
  mySqlDate: "YYYY-MM-DD HH:mm:ss"
};

/**
 * Konverterer en Date som en streng i et givet format. Med mindre formatet eksplicit er UTC formateres i lokal tid.
 * @param date Date som skal formateres
 * @param format Formatet som skal bruges til formatering. Som standard vil strengen kun indeholde datoen (ikke tidspunktet).
 */
export const formatDate = (date: Maybe<Date>, format: DateFormat = "d"): string => {
  if (isUndefinedOrNull(date)) {
    return "";
  }
  const m = format === "iso8601_utc" ? moment(date).utc() : moment(date);
  return m.format(dateFormatStrings[format]);
};

/**
 * Tolker en streng i henhold til det givne format.
 * Se dokumentationen af de mere specialiserede metoder for detaljer.
 */
export const parse = (dateString: Maybe<string>, format: Exclude<DateFormat, "iso8601_utc" | "iso8601_local"> | "iso8601" = "d", strict = false): Date | null => {
  if (isUndefinedOrNullOrEmpty(dateString)) {
    return null;
  }
  let m;
  if (format === "iso8601") {
    m = moment(dateString, moment.ISO_8601, strict);
  } else {
    m = moment(dateString, dateFormatStrings[format], strict);
  }
  if (!m.isValid()) {
    return null;
  }
  return m.toDate();
};

/**
 * Tolker en streng med dato og eventuel tid i ISO8601 formatet.
 * Formatet består af en dato og et tidspunkt adskilt af T.
 * Efter tidspunktet kan der står et offset (fx +01:00) som indikerer at tidspunktet er forskudt i forhold til UTC tid.
 * Alternativt kan der stå et Z som angiver at tidspunktet er UTC tid.
 * Hvis der ikke står noget efter tidspunktet er tidspunktet lokal-tid.
 * De samlede formater er:
 * år-måned-dagTtimer:minutter:sekunder:millisekunderZ
 * år-måned-dagTtimer:minutter:sekunder:millisekunder+timer:minutter
 * år-måned-dagTtimer:minutter:sekunder:millisekunder
 * år og måned er krævet. Resten er valgfrit.
 * T'et må kun være der hvis noget af tidspunktet også er der.
 * Z'et eller offsetet må kun være der hvis der er et tidspunkt.
 * Hvis dagen mangler sættes den til den 1.
 * Hvis noget af tidspunktet mangler sættes den manglende del til 0;
 * @param iso8601String Streng med dato og tid som skal tolkes.
 */
export const parseIso8601 = (iso8601String: Maybe<string>) => parse(iso8601String, "iso8601", true);
/**
 * Tolker en streng med en dato og returner en Date med den tolkede dato.
 * @param dateString Streng med dato som skal tokes.
 * @param strict Hvis true skal strengen have formatet dag-måned-år.
 * Hvis false og strengen mangler noget af datoen udfyldes det ud fra dags dato.
 * Hvis false og strengen også indeholder et klokkeslet ignoreres det.
 */
export const parseDateOnly = (dateString: Maybe<string>, strict = false) => parse(dateString, "d", strict);
/**
 * Tolker en streng med et klokkeslet og returnerer en Date med dags dato og det tolkede klokkeslet.
 * @param timeString Streng med klokkeslettet som skal tolkes.
 * @param format t = timer:minutter, ts = timer:minutter:sekunder
 * @param strict Hvis true skal strengen passe præsist med formatet.
 * Hvis false og noget af klokkeslettet mangler bliver den manglende del sat til 0.
 * Hvis false og formatet er t ignoreres eventuelle sekunder i klokkeslettet.
 */
export const parseTimeToday = (timeString: Maybe<string>, format: Extract<DateFormat, "t" | "ts"> = "ts", strict = false) => parse(timeString, format, strict);

/**
 * Tolker en streng med en dato og et tidspunkt og returnerer det som en Date.
 * @param dateTimeString Streng med dato og tid som skal tolkes.
 * @param format d = dag-måned-år, dt = dag-måned-år timer:minutter, dts = dag-måned-år timer:minutter:sekunder
 * @param strict Hvis true skal strengen passe præcists med formatet.
 * Hvis false og noget af klokkeslettet mangler bliver det manglende del sat til 0.
 * Hvis false og der noget af datoen mangler bliver den manglende del af datoen udfyldt ud fra dags dato.
 * Hvis false og der står mere i strengen end angivet i formatet ignoreres det.
 */
export const parseDateAndTime = (dateTimeString: string, format: Extract<DateFormat, "dt" | "dts"> = "dts", strict = false) => parse(dateTimeString, format, strict);

export interface ISetTime {
  (date: Date, hours: number, minutes: number, seconds?: number, milliseconds?: number): Date;
  (date: Date | undefined, hours: number, minutes: number, seconds?: number, milliseconds?: number): Date | undefined;
  (date: Date | null, hours: number, minutes: number, seconds?: number, milliseconds?: number): Date | null;
  (date: Maybe<Date>, hours: number, minutes: number, seconds?: number, milliseconds?: number): Maybe<Date>;
}
/**
 * Sætter klokkeslettet (lokal tid) på et Date objekt.
 */
export const setTime = ((date: Maybe<Date>, hours: number, minutes: number, seconds: number | undefined = 0, milliseconds: number | undefined = 0) =>
  hasValue(date)
    ? moment(date)
        .set("hours", hours)
        .set("minutes", minutes)
        .set("seconds", seconds || 0)
        .set("milliseconds", milliseconds || 0)
        .toDate()
    : date) as ISetTime;

type Unit = "years" | "months" | "days" | "hours" | "minutes";

export interface IAddToDate {
  (date: Date, n: number, unit: Unit): Date;
  (date: Date | undefined, n: number, unit: Unit): Date | undefined;
  (date: Date | null, n: number, unit: Unit): Date | null;
  (date: Maybe<Date>, n: number, unit: Unit): Maybe<Date>;
}
export const addToDate = ((date: Maybe<Date>, n: number, unit: Unit): Maybe<Date> => (hasValue(date) ? moment(date).add(n, unit).toDate() : date)) as IAddToDate;

export interface IAddMinutes {
  (date: Date, numberOfMinutes: number): Date;
  (date: Date | undefined, numberOfMinutes: number): Date | undefined;
  (date: Date | null, numberOfMinutes: number): Date | null;
  (date: Maybe<Date>, numberOfMinutes: number): Maybe<Date>;
}
export const addMinutes: IAddMinutes = (date: any, numberOfMinutes: any) => addToDate(date, numberOfMinutes, "minutes");

export interface IAddHours {
  (date: Date, numberOfHours: number): Date;
  (date: Date | undefined, numberOfHours: number): Date | undefined;
  (date: Date | null, numberOfHours: number): Date | null;
  (date: Maybe<Date>, numberOfHours: number): Maybe<Date>;
}
export const addHours: IAddHours = (date: any, numberOfHours: any) => addToDate(date, numberOfHours, "hours");

export interface IAddDays {
  (date: Date, numberOfDays: number): Date;
  (date: Date | undefined, numberOfDays: number): Date | undefined;
  (date: Date | null, numberOfDays: number): Date | null;
  (date: Maybe<Date>, numberOfDays: number): Maybe<Date>;
}
export const addDays: IAddDays = (date: any, numberOfDays: any) => addToDate(date, numberOfDays, "days");

export interface IAddMonths {
  (date: Date, numberOfMonths: number): Date;
  (date: Date | undefined, numberOfMonths: number): Date | undefined;
  (date: Date | null, numberOfMonths: number): Date | null;
  (date: Maybe<Date>, numberOfMonths: number): Maybe<Date>;
}
export const addMonths: IAddMonths = (date: any, numberOfMonths: any) => addToDate(date, numberOfMonths, "months");

export interface IAddYears {
  (date: Date, numberOfYears: number): Date;
  (date: Date | undefined, numberOfYears: number): Date | undefined;
  (date: Date | null, numberOfYears: number): Date | null;
  (date: Maybe<Date>, numberOfYears: number): Maybe<Date>;
}
export const addYears: IAddYears = (date: any, numberOfYears: any) => addToDate(date, numberOfYears, "years");
