

export class ApiResultError {
  public readonly ok: false;
  public readonly error: string;
  public constructor(error: string) { 
       
    this.ok = false;
    this.error = error;
  }
 
}
