import { actions as reservatinsActions } from "../../_redux/store/reservation-store";
import { actions as userActions} from "../../_redux/store/user-store";
import { RootState } from "../../_redux/store/rootReducer";
import {   AppointmentModel, GroupOrientation } from "@devexpress/dx-react-scheduler";
import { createReserVationDto, reservationDto } from "../../../../backend/model/reservation/reservation-dto";
import { formatDate } from "../../../../utils/date-time-utils";

export type AppointMentModelPartial = Partial<AppointmentModel>;

export const actions = {
    ...reservatinsActions,        
    clearUsers: userActions.clearUsers,                
}

export const mapStateToProps = (state: RootState) => ({reservations: state.reservations, users: state.users});

export type Props = ReturnType<typeof mapStateToProps> & typeof actions;

export type ContentProps = Pick<Props, "reservations" | "users" | "createReservationsRequest" | "deleteReservationsRequest" |"updateReservationsRequest">;

export const groupOrientation: GroupOrientation = "Horizontal";
export const grouping = [
    {
      resourceName: "conferenceroomId",
    },
  ];
  
export const createToModel = (addedAppointment: {[key: string]: any}, bruger:string) => {
  const mapedModel : createReserVationDto = {
    startDate:formatDate(addedAppointment["startDate"], "mySqlDate"),    
    endDate: formatDate(addedAppointment["endDate"], "mySqlDate"),    
    allDay: addedAppointment["allDay"],
    rRule: addedAppointment["rRule"],
    title: bruger,
    conferenceroomId: addedAppointment["conferenceroomId"],
    user: sessionStorage.getItem("EMAIL") ?? "",
    notes: addedAppointment["notes"],
  };

  return mapedModel;
};

export const updateToModel = (changedAppointment: AppointmentModel) => {
  const mapedModel : reservationDto = {
    id: changedAppointment.id ?? "",
    startDate: formatDate(new Date(changedAppointment.startDate), "mySqlDate"),     
    endDate: formatDate(new Date(changedAppointment.endDate), "mySqlDate"),
    allDay: changedAppointment?.allDay ?? false,
    rRule: changedAppointment?.rRule ?? "",
    title: changedAppointment?.title ?? "",
    conferenceroomId: changedAppointment.conferenceroomId,
    user: changedAppointment.user,
    notes: changedAppointment.notes,
  };  
  
  return mapedModel;
};