import "./App.css";
import { connect } from "react-redux";
import { Dashboard } from "./components";
import { RootState } from "./domain";
import "devextreme/dist/css/dx.light.css";
import { useEffect } from "react";
import { loadMessages, locale } from "devextreme/localization";
import dkMessages from "./utils/localization/dk.json";
import { hasValue } from "./utils";
import { actions } from "./domain/booking/_redux/store/user-store";

const mapStateToProps = (state: RootState) => state.users;
type Props = ReturnType<typeof mapStateToProps> & typeof actions;

const AppBase = (props: Props) => {
  const { setTokenRequest, getUserRequest, token, email } = props;

  useEffect(() => {
    if (hasValue(token) && hasValue(email)) {
      setTokenRequest(token, email, sessionStorage.getItem("PHOTO") ?? "");
      getUserRequest(email);
    }

    loadMessages(dkMessages);
    locale("DK");
  }, [setTokenRequest, getUserRequest, token, email]);

  return <Dashboard />;
};

export const App = connect(mapStateToProps, actions)(AppBase);
