import { SvgIcon } from "@mui/material";
import { RootState } from "../../domain/booking/_redux/store/rootReducer";
import { actions } from "../../domain/booking/_redux/store/user-store";

export const mapStateToProps = (state: RootState) => state.users;
export type Props = ReturnType<typeof mapStateToProps> & typeof actions;

export type ButtonText = "Log ind" | "Log ud";

export type LoginInfo = {
    name:string;
    email:string;
    photoUrl?:string;
    tokenId?:string;
    id?:number;
    admin?: boolean;
    company: string;
}

export type menuItems = {
    name: string;
    component: JSX.Element;
    icon: typeof SvgIcon;
  };

 