import { useEffect } from "react";
import { connect } from "react-redux";

import { actions, mapStateToProps, Props } from "./calendar-types";
import { Content } from "./calendar-base";
import { LoginImage } from "../../../../components/dashboard/components/login-image";

const CalendarBase = (props: Props) => {
  const {
    fetchtReservationsRequest,
    fetchtResourceRequest,
    clearReservations,
    createReservationsRequest,
    clearUsers,
    reservations,
    users,
    deleteReservationsRequest,
    updateReservationsRequest,
  } = props;

  useEffect(() => {
    fetchtReservationsRequest();
    fetchtResourceRequest();

    return () => {
      clearReservations();
    };
  }, [fetchtReservationsRequest, fetchtResourceRequest, clearReservations]);

  if (users.loadingUser.loadingState === "failed") {
    clearUsers();
    return <LoginImage />;
  }

  return (
    <Content
      createReservationsRequest={createReservationsRequest}
      reservations={reservations}
      deleteReservationsRequest={deleteReservationsRequest}
      updateReservationsRequest={updateReservationsRequest}
      users={users}
    />
  );
};

export const Calendar = connect(mapStateToProps, actions)(CalendarBase);
