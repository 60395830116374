type LocalizationMessages = {
    /** The "All Day" editor's label text. */
    allDayLabel?: string;
    /** The "Title" editor's label text. */
    titleLabel?: string;
    /** The commit button's text. */
    commitCommand?: string;
    /** The "More Information" editor’s label text. */
    moreInformationLabel?: string;
    /** The "Repeat" editor’s label text. */
    repeatLabel?: string;
    /** The "Notes" editor’s label text. */
    notesLabel?: string;
    /** The "Never" label text. */
    never?: string;
    /** The "Daily" label text. */
    daily?: string;
    /** The "Weekly" label text. */
    weekly?: string;
    /** The "Monthly" label text. */
    monthly?: string;
    /** The "Yearly" label text. */
    yearly?: string;
    /** The "Repeat every" label text. */
    repeatEveryLabel?: string;
    /** The "day(s)" label text. */
    daysLabel?: string;
    /** The "End repeat" label text. */
    endRepeatLabel?: string;
    /** The "On" label text. */
    onLabel?: string;
    /** The "After" label text. */
    afterLabel?: string;
    /** The "Occurrences" label text. */
    occurrencesLabel?: string;
    /** The "week(s) on:" label text. */
    weeksOnLabel?: string;
    /** The "month(s)" label text. */
    monthsLabel?: string;
    /** The "of every month" label text. */
    ofEveryMonthLabel?: string;
    /** The "The" label text. */
    theLabel?: string;
    /** The "First" label text. */
    firstLabel?: string;
    /** The "Second" label text. */
    secondLabel?: string;
    /** The "Third" label text. */
    thirdLabel?: string;
    /** The "Fourth" label text. */
    fourthLabel?: string;
    /** The "Last" label text. */
    lastLabel?: string;
    /** The "year(s)" label text. */
    yearsLabel?: string;
    /** The "of" label text. */
    ofLabel?: string;
    /** The "Every" label text. */
    everyLabel?: string;
    /** The "Details" label text. */
    detailsLabel?: string;
  }

export const mesages: LocalizationMessages = {
    titleLabel: "Emne",
    allDayLabel: "Hele dagen",
    commitCommand: "Gem",
    repeatLabel: "Gentag",
    repeatEveryLabel: "Gentag hver",
    moreInformationLabel: "Beskrivelse",
    daily: "Dagligt",
    weekly: "Hver uge",
    monthly: "Hver måned",
    yearly: "Hvert år",
    never: "Aldrig",
    onLabel: "Ved",
    afterLabel: "Efter",
    daysLabel: "Dage",
    detailsLabel: "Detaljer",
    endRepeatLabel: "Stop gentagelse efter",
    everyLabel: "Hver",
    occurrencesLabel: "hændelse(r)",
          
  };  