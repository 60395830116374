import { combineReducers } from "redux";
import { reservationReducer } from "./reservation-store";
import { userReducer } from "./user-store";

const rootReducer = combineReducers({
    reservations: reservationReducer,
    users: userReducer
})

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;