type LoadingStateNotStarted = "not-started";
type LoadingStateIsLoading = "is-loading";
type LoadingStateFailed = "failed";
type LoadingStateHasFinished = "has-finished";
export type LoadingState = LoadingStateNotStarted | LoadingStateIsLoading | LoadingStateFailed | LoadingStateHasFinished;

type LoadingNotStated = { loadingState: LoadingStateNotStarted };
type LoadingIsLoading = { loadingState: LoadingStateIsLoading };
type LoadingFailed = { loadingState: LoadingStateFailed; errorMessage?: string };
type LoadingHasFinished<T> = { loadingState: LoadingStateHasFinished; data: T };
export type Loading<T> = LoadingNotStated | LoadingIsLoading | LoadingFailed | LoadingHasFinished<T>;

export const loadingNotStarted: LoadingNotStated = { loadingState: "not-started" };

export const loadingIsLoading: LoadingIsLoading = { loadingState: "is-loading" };

/**
 * You can use `loadingFailedMessage` if tou want to include an errormessage.
 * */
export const loadingFailed: LoadingFailed = { loadingState: "failed" };

export const loadingFailedMessage = (errorMessage: string): LoadingFailed => ({ loadingState: "failed", errorMessage });

export const loadingHasFinished = <T>(data: T): LoadingHasFinished<T> => ({ loadingState: "has-finished", data });

export const ensureLoadingHasFinished = <T>(loading: Loading<T>, message?: string): T => {
  if (loading.loadingState !== "has-finished") throw Error(`ensureLoadingHasFinished (loadingState is ${loading.loadingState}): ${message ?? "did not expect this"}.`);
  return loading.data;
};