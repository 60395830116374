import stringify, { Comparator } from "json-stable-stringify";

import { serializeDate } from "./serialize-utils";

// stringify fungerer noget i den her retning
//  function stringify(obj, options)
//    den kalder en metode som tager et objekt, et felt navn, feltets værdi og options
//    stringifyField({"":obj}, "", obj, options);
//
//  function stringifyField(obj, key, value, options)
//    if value contains toJSON()
//      value = value.toJSON();
//    if options.replacer is set
//      value = options.replacer(obj, key, value);
//    if value is object
//      foreach key in value (ordered according to comparer in options)
//        stringifyField(value, keyInValue, value[keyInValue], options);
//    håndter andre value typer

// For at lave deserialiseringen på serveren mere effektiv placerer vi metadata-felter først.
// Det gør det muligt at læse typen fra metadatafelterne fra en stream
// og derefter vide med det samme hvilken type det er der skal deserialiseres til.
// Hvis de ikke stod forest ville man skulle læse hele streamen før
// man kunne begynde på at oprette objekter.
const comparator: Comparator = (a, b) => {
  const orderA = a.key.startsWith("$") ? 1 : 2;
  const orderB = b.key.startsWith("$") ? 1 : 2;
  return orderA - orderB;
};

// Funktionen er ikke lavet som en fat-arrow function, da vi er afhængige af at stringify kan sætte 'this' til objektet som property'en ligger på
function replacer(this: any, key: string, value: any): any {
  const obj = this[key];
  return obj instanceof Date ? serializeDate(key, obj) : value;
}

const parseReviver = (_: string, value: any) => {
  if (value === null) {
    return null;
  }
  if (typeof value === "string") {
    // ISO 8601 faktisk tillader flere variationer end dette, men vi tillader kun det
    // eslint-disable-next-line no-useless-escape
    const iso8601Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?([zZ]|([\+\-]\d{2}:\d{2}))?$/;
    if (value.match(iso8601Pattern)) {
      return new Date(value);
    }
    return value;
  }
  if (typeof value === "object" && value.$values) {
    return value.$values;
  }
  return value;
};

export const parseJson = (json: string | null | undefined) => (json === null || json === undefined || json === "" ? undefined : JSON.parse(json, parseReviver));
export const stringifyToJson = (obj: any) => stringify(obj, { cmp: comparator, replacer });
