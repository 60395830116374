
import { serverClient } from "../../../../../backend/client/domains/server-client";

import {call, put, all, fork, takeEvery} from "typed-redux-saga";
import {actions, ActionTypes} from "../../store/user-store"
import { LoginInfo } from "../../../../../components/dashboard/dashboard-types";

function* fetchUsers() {       
       const result = yield* call(serverClient.users.getUsersAsync);       
       if(result.ok){
           yield* put(actions.fetchUsersSucces(result.data));                               
       }else{            
           yield* put(actions.fetchUsersFailure(result.error));
       } 
}

function* getUser(action:  ReturnType<typeof actions.getUserRequest>) {    
    const result = yield* call(serverClient.users.getUserAsync, action.payload.email);    
    if(result.ok){
        yield* put(actions.getUserSucces(result.data));
    }else{
        yield* put(actions.getUserFailure());
    }
}

function* createUser(action: ReturnType<typeof actions.createUserRequest>){    
    const result = yield* call(serverClient.users.createUserAsync, {...action.payload.addedUser, admin:false})
    if(result.ok){
        const createdData = result.data;        
        const users:LoginInfo[] = [...action.payload.users, createdData];        
        yield* put(actions.createUserSuccess(users));
    }else{
        yield* put(actions.createUserFail(result.error));
    }
}

function* deleteUser(action: ReturnType<typeof actions.deleteUserRequest>){
    const result = yield* call(serverClient.users.deleteUserAsync, action.payload.id);
    if(result.ok){
        const reservations = action.payload.users.filter((x) => x.id !== action.payload.id);
        yield* put(actions.deleteUserSuccess(reservations));
    }else{
        yield* put(actions.deleteUserFail(result.error));
    }
}

function* removeToken(){
    sessionStorage.removeItem("TOKEN");
    sessionStorage.removeItem("EMAIL");
    sessionStorage.removeItem("PHOTO");
    yield* put(actions.removeTokenSucces());
}


function* setToken(action: ReturnType<typeof actions.setTokenRequest>){
    sessionStorage.setItem("TOKEN", action.payload.token);
    sessionStorage.setItem("EMAIL", action.payload.email);
    sessionStorage.setItem("PHOTO", action.payload.photoUrl);
    yield* put(actions.setTokenSucces(action.payload.token, action.payload.email, action.payload.photoUrl));
}

function* watchDeleteUser(){
    yield* takeEvery(ActionTypes.DELETE_USER_REQUEST, deleteUser);
}

function* watchCreateUser(){
    yield* takeEvery(ActionTypes.CREATE_USER_REQUEST, createUser);
}

function* watchGetUser(){
    yield* takeEvery(ActionTypes.GET_USER_REQUEST, getUser);
}

function* watchSetToken(){
    yield* takeEvery(ActionTypes.SET_TOKEN_REQUEST, setToken);
}

function* watchRemoveToken(){
    yield* takeEvery(ActionTypes.REMOVE_TOKEN_REQUEST, removeToken);
}

function* watchFetchUsers(){
    yield* takeEvery(ActionTypes.FETCH_USERS_REQUEST, fetchUsers);
}

export function* usersSaga() {
    yield all([fork(watchFetchUsers), fork(watchSetToken), fork(watchGetUser), fork(watchRemoveToken), fork(watchCreateUser), fork(watchDeleteUser)]);
  } 