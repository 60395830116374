import styled from "styled-components";
import cx from "clsx";
import {
  Root,
  getHeader,
  getDrawerSidebar,
  getSidebarTrigger,
  getSidebarContent,
  getCollapseBtn,
  getContent,
  getFixedScheme,
} from "@mui-treasury/layout";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  ListItem,
  ListItemText,
  Box,
  Avatar,
  Divider,
  ListItemAvatar,
} from "@mui/material";

import { useEffect, useMemo, useState } from "react";

import { MenuList, Login } from "./components";
import { LoginInfo, mapStateToProps, Props } from "./dashboard-types";
import { dashBoardStyles } from "./dashboard-styles";
import { LoginImage } from "./components/login-image";
import { Calendar } from "../../domain";
import { actions } from "../../domain/booking/_redux/store/user-store";
import { connect } from "react-redux";
import { hasValue } from "../../utils";

const Header = getHeader(styled);
const DrawerSidebar = getDrawerSidebar(styled);
const SidebarTrigger = getSidebarTrigger(styled);
const SidebarContent = getSidebarContent(styled);
const CollapseBtn = getCollapseBtn(styled);
const Content = getContent(styled);
const fixedScheme = getFixedScheme();

const useStyles = dashBoardStyles();

export const DashboardBase = (props: Props) => {
  const { loadingUser, token, photoUrl } = props;

  const [component, setComponent] = useState<JSX.Element>();

  const user = useMemo<Partial<LoginInfo>>(() => {
    return loadingUser.loadingState === "has-finished"
      ? loadingUser.data.user
      : {};
  }, [loadingUser]);

  useEffect(() => {
    if (hasValue(token)) {
      setComponent(<Calendar />);
    } else {
      setComponent(<LoginImage />);
    }
  }, [token]);

  const menuList = useMemo(() => {
    return (
      <MenuList
        setComponent={setComponent}
        disabled={!hasValue(token)}
        isAdmin={hasValue(user.admin) ? user.admin : false}
      />
    );
  }, [token, user.admin]);

  const styles = useStyles();

  return (
    <Root scheme={fixedScheme}>
      <CssBaseline />
      <Header className={styles.header}>
        <Toolbar>
          <Box sx={{ mr: 2 }}>
            <SidebarTrigger sidebarId={"primarySidebar"} />
          </Box>
          <Box sx={{ flexGrow: 1 }}>{"Booking af mødelokaler"}</Box>
          <Login />
        </Toolbar>
      </Header>
      <DrawerSidebar
        sidebarId={"primarySidebar"}
        PaperProps={{
          className: styles.sidebar,
        }}
      >
        <SidebarContent style={{ overflow: "hidden" }}>
          <ListItem>
            <ListItemAvatar>
              <Avatar alt={user.name} src={photoUrl ?? undefined} />
            </ListItemAvatar>
            <ListItemText
              secondaryTypographyProps={{ color: "white" }}
              primary={user.name}
              secondary={user.email}
              sx={{ color: "white" }}
            />
          </ListItem>
          <Divider
            light
            sx={{
              backgroundColor: "gray",
            }}
          />
          {menuList}
        </SidebarContent>
        <CollapseBtn className={cx(styles.collapseBtn)} />
      </DrawerSidebar>
      <Content>{component}</Content>
    </Root>
  );
};

export const Dashboard = connect(mapStateToProps, actions)(DashboardBase);
