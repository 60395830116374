import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import { useEffect, useState } from "react";
import { RootState } from "../../_redux/store/rootReducer";
import { actions } from "../../_redux/store/user-store";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
} from "@devexpress/dx-react-grid-material-ui";
import { ChangeSet, Column, EditingState } from "@devexpress/dx-react-grid";
import { hasValue } from "../../../../utils/object-utils";
import { LoginInfo } from "../../../../components/dashboard/dashboard-types";

export const mapStateToProps = (state: RootState) => state.users;
export type Props = ReturnType<typeof mapStateToProps> & typeof actions;

const getRowId = (row: any) => row.id;

export const UserBase = (props: Props) => {
  const { loading, fetchtUsersRequest, createUserRequest, deleteUserRequest } =
    props;
  const [users, setUsers] = useState<LoginInfo[]>();

  const columns: Column[] = [
    { name: "name", title: "Navn" },
    { name: "email", title: "Email" },
    { name: "company", title: "Firma" },
  ];

  const commitChanges = (changes: ChangeSet) => {
    if (hasValue(changes.added) && hasValue(changes.added[0])) {
      const addedRow: LoginInfo = { ...changes.added[0] };

      createUserRequest(hasValue(users) ? users : [], addedRow);
    }

    if (hasValue(changes.deleted)) {
      const id = changes.deleted[0];
      if (hasValue(id)) {
        deleteUserRequest(hasValue(users) ? users : [], id);
      }
    }
  };

  useEffect(() => {
    fetchtUsersRequest();
  }, [fetchtUsersRequest]);

  useEffect(() => {
    if (loading.loadingState === "has-finished") {
      setUsers(loading.data.users);
    }
  }, [loading.loadingState, loading]);

  if (loading.loadingState === "has-finished") {
    return (
      <Paper>
        <Grid rows={loading.data.users} columns={columns} getRowId={getRowId}>
          <EditingState onCommitChanges={commitChanges} />
          <Table />
          <TableHeaderRow />
          <TableEditRow />
          <TableEditColumn
            showAddCommand
            showDeleteCommand
            messages={{
              deleteCommand: "Slet",
              addCommand: "TIlføj bruger",
              cancelCommand: "Fortryd",
              commitCommand: "Gem",
              editCommand: "Rediger",
            }}
          />
        </Grid>
      </Paper>
    );
  } else {
    return null;
  }
};

export const User = connect(mapStateToProps, actions)(UserBase);
