import { StatusCodes } from "http-status-codes";
import { hasValue, buildUrl, Param, stringifyToJson, parseJson  } from "../utils";
import { ApiDataResult } from "./api-data-result";
import { ApiDataResultSuccess } from "./api-data-result-success";
import { ApiResult } from "./api-result";
import { ApiResultError } from "./api-result-error";
import { ApiResultSuccess } from "./api-result-success";

interface IOkResult {
  resultValue: unknown;
}

interface IErrorResult {
  notification: {
    _errors: Notification[];
  };
}

//const isOkResult = (result: any): result is IOkResult => hasValue(result.resultValue);
const isErrorResult = (result: any): result is IErrorResult => hasValue(result.notification) && hasValue(result.notification._errors);

export class HttpClient {
  public constructor(private readonly baseUrl?: string, private readonly getCustomHeadersAsync?: () => Promise<HeadersInit>) {}

  public getAsync<T>(url: string, params?: Record<string, Param>): Promise<ApiDataResult<T>> {
    return this._executeExpectDataAsync<T>("GET", url, params);
  }

  public getAsyncVoid(url: string, params?: Record<string, Param>): Promise<ApiResult> {
    return this._executeAsync("GET", url, params);
  }

  public postAsync<T>(url: string, params?: Record<string, Param>, data?: unknown): Promise<ApiDataResult<T>> {
    return this._executeExpectDataAsync("POST", url, params, data);
  }

  public postAsyncVoid(url: string, params?: Record<string, Param>, data?: unknown): Promise<ApiResult> {
    return this._executeAsync("POST", url, params, data);
  }

  public putAsync<T>(url: string, params?: Record<string, Param>, data?: unknown): Promise<ApiDataResult<T>> {
    return this._executeExpectDataAsync("PUT", url, params, data);
  }

  public putAsyncVoid(url: string, params?: Record<string, Param>, data?: unknown): Promise<ApiResult> {
    return this._executeAsync("PUT", url, params, data);
  }

  public deleteAsync<T>(url: string, params?: Record<string, Param>): Promise<ApiDataResult<T>> {
    return this._executeExpectDataAsync("DELETE", url, params);
  }

  public deleteAsyncVoid(url: string, params?: Record<string, Param>): Promise<ApiResult> {
    return this._executeAsync("DELETE", url, params);
  }

  private async _executeExpectDataAsync<T>(method: string, url: string, params?: Record<string, Param>, data?: unknown): Promise<ApiDataResult<T>> {
    const result = await this._executeAsync(method, url, params, data);
    // We can't ensure that the result acutally contains data because if T is "string | undefined"
    // then it is not an error if there isn't any data, and on runtime we can't determine if
    // T can be undefined.
    return result as ApiDataResult<T>;
  }

  private async _executeAsync(method: string, url: string, params?: Record<string, Param>, data?: unknown): Promise<ApiResult> {
    
    const baseUrl = new URL(url, this.baseUrl);
    const urlWithParameters = buildUrl(baseUrl, params);
    //const user:LoginInfo = JSON.parse(localStorage.getItem('USER') ?? "");
    const token = sessionStorage.getItem("TOKEN");    
    const options: RequestInit = {     
      method,         
      headers:{ 
        Authorization: `Bearer ${token}`,                      
      },      
      body: data ? stringifyToJson(data) : undefined      
    };    

    try {         
      const response = await fetch(urlWithParameters, options);
            
      let body: IOkResult | IErrorResult | string | undefined;
      if (response.status !== StatusCodes.NO_CONTENT) {             
        body = parseJson(await response.text());         
      }

      if (response.ok) {
        if (!hasValue(body) || body === "") {
          return new ApiResultSuccess();
        }        
        
        if (hasValue(body)) {                  
          return new ApiDataResultSuccess(body);
        }
        
        return new ApiResultError(await response.text());
      }

      // If the API call failed we must find the right error message to show to the user.
      let errorMessage: string | undefined;

      if (hasValue(body) && response.status !== StatusCodes.UNAUTHORIZED) {
        if (isErrorResult(body) ) {
          return new ApiResultError(await response.text());
        }
        errorMessage = "teknisk fejl"
      } else if (response.status === StatusCodes.UNAUTHORIZED) {
        errorMessage = "ingen adgang";
      }
      if (!hasValue(errorMessage)) {
        errorMessage = "api fejl";
      }
      return new ApiResultError(errorMessage ?? "");
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : (error as any).toString();
      return new ApiResultError(errorMessage ?? "");
    }
  }
}
