import { Avatar, Grid } from "@mui/material";

import loginImage from "../image/logo-firsthub.png";
export const LoginImage = () => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={3}>
        <Avatar
          src={loginImage}
          sx={{ width: "75%", height: "75%", left: "12.5%" }}
        />
      </Grid>
    </Grid>
  );
};
