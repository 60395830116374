import {AppointmentModel, Resource} from "@devexpress/dx-react-scheduler";
import { Reducer } from "redux";
import { action as actionCreator, ActionType } from "typesafe-actions";
import { createReserVationDto, reservationDto } from "../../../../../backend/model";
import { Loading, loadingFailed, loadingHasFinished, loadingIsLoading, loadingNotStarted } from "../../../../../utils";

export enum ActionTypes{    
    FETCH_RESERVATION_REQUEST = "FETCH_RESERVATION_REQUEST",
    FETCH_RESERVATION_SUCCESS = "FETCH_RESERVATION_SUCCESS",
    FETCH_RESERVATION_FAILURE = "FETCH_RESERVATION_FAILURE",

    FETCH_RESOURCE_REQUEST = "FETCH_RESOURCE_REQUEST",
    FETCH_RESOURCE_SUCCESS = "FETCH_RESOURCE_SUCCESS",
    FETCH_RESOURCE_FAILURE = "FETCH_RESOURCE_FAILURE",
    
    CREATE_RESERVATION_REQUEST = "CREATE_RESERVATION_REQUEST",
    CREATE_RESERVATION_SUCCESS = "CREATE_RESERVATION_SUCCESS",
    CREATE_RESERVATION_FAILURE = "CREATE_RESERVATION_FAILURE",
    
    DELETE_RESERVATION_REQUEST = "DELETE_RESERVATION_REQUEST",
    DELETE_RESERVATION_SUCCES = "DELETE_RESERVATION_SUCCES",
    DELETE_RESERVATION_FAIL = "DELETE_RESERVATION_FAIL",

    UPDATE_RESERVATOIN_REQUEST = "UPDATE_RESERVATOIN_REQUEST",
    UPDATE_RESERVATOIN_SUCCES = "UPDATE_RESERVATOIN_SUCCES",
    UPDATE_RESERVATOIN_FAIL = "UPDATE_RESERVATOIN_FAIL",

    CLEAR_RESERVATION = "CLEAR_RESERVATION",
}

export const actions = {
    fetchtReservationsRequest: () => actionCreator(ActionTypes.FETCH_RESERVATION_REQUEST),
    fetchReservationsSucces: (reservations: AppointmentModel[]) => actionCreator(ActionTypes.FETCH_RESERVATION_SUCCESS, {reservations}),
    fetchReservationsFailure: () => actionCreator(ActionTypes.FETCH_RESERVATION_FAILURE),

    fetchtResourceRequest: () => actionCreator(ActionTypes.FETCH_RESOURCE_REQUEST),
    fetchResourceSucces: (resources:Resource[]) => actionCreator(ActionTypes.FETCH_RESOURCE_SUCCESS, {resources}),
    fetchResourceFailure: () => actionCreator(ActionTypes.FETCH_RESOURCE_FAILURE),
    
    createReservationsRequest: (reservations: AppointmentModel[], addedReservation: createReserVationDto) => actionCreator(ActionTypes.CREATE_RESERVATION_REQUEST, {reservations, addedReservation}),
    createReservationsSucces: (reservations: AppointmentModel[]) => actionCreator(ActionTypes.CREATE_RESERVATION_SUCCESS, {reservations}),
    createReservationsFailure: (error:string) => actionCreator(ActionTypes.CREATE_RESERVATION_FAILURE, {error}),

    updateReservationsRequest: (reservations: AppointmentModel[], updatedReservation: reservationDto) => actionCreator(ActionTypes.UPDATE_RESERVATOIN_REQUEST, {reservations, updatedReservation}),
    updateReservationsSucces: (reservations: AppointmentModel[]) => actionCreator(ActionTypes.UPDATE_RESERVATOIN_SUCCES, {reservations}),
    updateReservationsFailure: (error:string) => actionCreator(ActionTypes.UPDATE_RESERVATOIN_FAIL, {error}),

    deleteReservationsRequest: (reservations: AppointmentModel[], id:number|string|undefined) => actionCreator(ActionTypes.DELETE_RESERVATION_REQUEST, {reservations, id}),
    deleteReservationsSuccess: (reservations: AppointmentModel[]) => actionCreator(ActionTypes.DELETE_RESERVATION_SUCCES, {reservations}),
    deleteReservationsFail: (error: string) => actionCreator(ActionTypes.DELETE_RESERVATION_FAIL, {error}),

    clearReservations: () => actionCreator(ActionTypes.CLEAR_RESERVATION),    
}

type ReservationState = {
    loadingReservations: Loading<{reservations: AppointmentModel[] }>;
    loadingResources:Loading<{resource: Resource[]}>    
    isSubmiting:boolean;
    submitError:string | undefined    
}

const initialState: ReservationState = {
    loadingReservations: loadingNotStarted,
    loadingResources: loadingNotStarted,
    isSubmiting: false,
    submitError: undefined    
};

export const reservationReducer: Reducer<ReservationState, ActionType<typeof actions>> = (state = initialState, action) => {
    
    switch (action.type) {
        case ActionTypes.FETCH_RESERVATION_REQUEST:
           return {...state, loadingReservations: loadingIsLoading };
        case ActionTypes.FETCH_RESERVATION_SUCCESS:            
            return {...state, loadingReservations:loadingHasFinished({reservations:action.payload.reservations})};
        case ActionTypes.FETCH_RESERVATION_FAILURE:
            return{...state, loadingReservations:loadingFailed};

            case ActionTypes.FETCH_RESOURCE_REQUEST:
                return {...state, loadingReservations: loadingIsLoading };
             case ActionTypes.FETCH_RESOURCE_SUCCESS:            
                 return {...state, loadingResources:loadingHasFinished({resource:action.payload.resources})};
             case ActionTypes.FETCH_RESOURCE_FAILURE:
                 return{...state, loadingReservations:loadingFailed};

        case ActionTypes.CREATE_RESERVATION_REQUEST:
        case ActionTypes.DELETE_RESERVATION_REQUEST:
        case ActionTypes.UPDATE_RESERVATOIN_REQUEST:
            return {...state, loadingReservations: loadingIsLoading, isSubmiting:true};
        case ActionTypes.CREATE_RESERVATION_SUCCESS:
        case ActionTypes.DELETE_RESERVATION_SUCCES:
        case ActionTypes.UPDATE_RESERVATOIN_SUCCES:
            return {...state, loadingReservations:loadingHasFinished({reservations:action.payload.reservations}), isSubmiting:false};
        case ActionTypes.CREATE_RESERVATION_FAILURE:
        case ActionTypes.DELETE_RESERVATION_FAIL:
        case ActionTypes.UPDATE_RESERVATOIN_FAIL:
            return{...state, isSubmiting:false, submitError: action.payload.error}

        case ActionTypes.CLEAR_RESERVATION:
            return initialState;

        default:
            return{...state};
    }
};       