import { Calendar, User  } from "../../../domain";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";

export const adminMenu = [
    {
      name: "Kalender",
      component: Calendar,
      icon: EventOutlinedIcon,
    },
    {
      name: "Brugere",
      component: User,
      icon: PeopleOutlineOutlinedIcon,
    },
  ];
  
export  const userMenu = [
    {
      name: "Kalender",
      component: Calendar,
      icon: EventOutlinedIcon,
    },
  ];