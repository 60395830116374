import Paper from "@material-ui/core/Paper";

import {
  Scheduler,
  Appointments,
  AppointmentTooltip,
  Resources,
  GroupingPanel,
  WeekView,
  Toolbar,
  DateNavigator,
  TodayButton,
  MonthView,
  DayView,
  ViewSwitcher,
  AppointmentForm,
  EditRecurrenceMenu,
} from "@devexpress/dx-react-scheduler-material-ui";

import {
  ViewState,
  GroupingState,
  IntegratedGrouping,
  EditingState,
  ChangeSet,
  AppointmentModel,
  Resource,
} from "@devexpress/dx-react-scheduler";

import { Grid, CircularProgress } from "@mui/material";
import {
  ContentProps,
  createToModel,
  grouping,
  groupOrientation,
  updateToModel,
} from "./calendar-types";

import { useEffect, useState } from "react";
import { mesages } from "../../../../utils/localization/appointment-form";
import { DateEditor, TodayButtonEdit } from "./components/calendar-components";
import { hasValue } from "../../../../utils";
import { LoginInfo } from "../../../../components/dashboard/dashboard-types";

export const Content = (props: ContentProps) => {
  const [date, setDate] = useState<Date>(new Date());
  const [currentViewName, setCurrentViewname] = useState<string>("work-week");
  const [appointments, setAppointments] = useState<AppointmentModel[]>();
  const [resources, setRescourses] = useState<Resource[]>();
  const [id, setId] = useState<number | string | undefined>();
  const [editAppointment, setEditAppointment] =
    useState<Partial<AppointmentModel>>();
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [appointmentFormVisible, setAppointmentFormVisible] =
    useState<boolean>(false);

  const [user, setUser] = useState<LoginInfo>();

  const {
    reservations,
    users,
    createReservationsRequest,
    deleteReservationsRequest,
    updateReservationsRequest,
  } = props;

  const onCurrentDateChange = (currentDate: Date) => {
    setDate(currentDate);
  };

  const onCurrentViewNameChange = (viewName: string) => {
    setCurrentViewname(viewName);
  };

  const editChange = (edit: Partial<AppointmentModel>) => {
    setId(edit?.id);
    setEditAppointment(edit);
  };

  const onCommitChanges = (changes: ChangeSet) => {
    const titleText = hasValue(user) ? user.company + ": " + user.name : "";
    if (hasValue(changes.added)) {
      createReservationsRequest(
        hasValue(appointments) ? appointments : [],
        createToModel(changes.added, titleText),
      );
    }

    if (hasValue(changes.changed)) {
      const changedObject = changes.changed;
      if (hasValue(id) && hasValue(changedObject)) {
        const data = appointments?.find((x) => x.id === id);
        if (hasValue(data)) {
          const updatedData = { ...data, ...changedObject[id] };
          updateReservationsRequest(
            hasValue(appointments) ? appointments : [],
            updateToModel(updatedData),
          );
        }
      }
    }

    if (hasValue(changes.deleted)) {
      deleteReservationsRequest(
        hasValue(appointments) ? appointments : [],
        changes.deleted,
      );
    }
  };

  const editingVisible = (email: string) => {
    const user =
      users.loadingUser.loadingState === "has-finished"
        ? users.loadingUser.data.user
        : undefined;
    const userIsAdmin = hasValue(user)
      ? hasValue(user.admin)
        ? user.admin
        : false
      : false;

    if (email !== user?.email && userIsAdmin === false) {
      return false;
    }
    return true;
  };

  const tooltipLayout = (props: AppointmentTooltip.LayoutProps) => {
    const { showDeleteButton, showOpenButton, ...rest } = props;

    const email = hasValue(props.appointmentMeta?.data.user)
      ? props.appointmentMeta?.data.user
      : "";

    let show = props.visible ?? false;
    if (!editingVisible(email)) {
      show = false;
    }

    return (
      <AppointmentTooltip.Layout
        showDeleteButton={show}
        showOpenButton={show}
        {...rest}
      />
    );
  };

  const formLayout = (props: AppointmentForm.BasicLayoutProps) => {
    const { readOnly, ...rest } = props;
    let readonly = readOnly;

    if (
      hasValue(props.appointmentData.user) &&
      props.appointmentData.user !== user?.email
    ) {
      readonly = true;
    }

    return <AppointmentForm.BasicLayout readOnly={readonly} {...rest} />;
  };

  const commandLayout = (props: AppointmentForm.CommandLayoutProps) => {
    const { readOnly, ...rest } = props;
    let readonly = readOnly;
    if (
      hasValue(editAppointment) &&
      hasValue(editAppointment.user) &&
      editAppointment.user !== user?.email
    ) {
      readonly = true;
    }

    return <AppointmentForm.CommandLayout readOnly={readonly} {...rest} />;
  };

  const textEditorComponent = (props: AppointmentForm.TextEditorProps) => {
    const { readOnly, placeholder, ...rest } = props;

    if (props.type === "titleTextEditor") {
      return (
        <AppointmentForm.TextEditor
          placeholder="Title bliver genereret automatisk"
          readOnly={true}
          {...rest}
        />
      );
    }

    return <AppointmentForm.TextEditor {...props} />;
  };

  useEffect(() => {
    if (reservations.loadingReservations.loadingState === "has-finished") {
      setAppointments(reservations.loadingReservations.data.reservations);
    }

    if (reservations.loadingReservations.loadingState === "failed") {
      setAppointments([]);
    }
  }, [
    reservations.loadingReservations.loadingState,
    reservations.loadingReservations,
  ]);

  useEffect(() => {
    if (reservations.loadingResources.loadingState === "has-finished") {
      setRescourses(reservations.loadingResources.data.resource);
    }
  }, [
    reservations.loadingResources.loadingState,
    reservations.loadingResources,
  ]);

  useEffect(() => {
    if (users.loadingUser.loadingState === "has-finished") {
      setUser(users.loadingUser.data.user);
    }
  }, [users.loadingUser.loadingState, users.loadingUser]);

  if (hasValue(appointments) && hasValue(resources)) {
    return (
      <Paper>
        <Scheduler data={appointments} locale={"da-DK"}>
          <ViewState
            defaultCurrentDate={date}
            currentViewName={currentViewName}
            onCurrentDateChange={onCurrentDateChange}
            onCurrentViewNameChange={onCurrentViewNameChange}
          />
          <WeekView startDayHour={7} endDayHour={20} displayName="Uge" />
          <WeekView
            startDayHour={7}
            endDayHour={20}
            name="work-week"
            displayName="Arbejdsuge"
            excludedDays={[0, 6]}
          />
          <MonthView displayName="Måned" />
          <DayView displayName="Dag" />
          <Appointments />
          <Resources data={resources} mainResourceName="conferenceroomId" />
          <GroupingState
            grouping={grouping}
            groupOrientation={() => groupOrientation}
          />
          <IntegratedGrouping />
          <GroupingPanel />
          <EditingState
            onCommitChanges={onCommitChanges}
            onEditingAppointmentChange={editChange}
          />
          <EditRecurrenceMenu />
          <AppointmentTooltip
            showOpenButton
            showDeleteButton
            visible={tooltipVisible}
            onVisibilityChange={(x) => setTooltipVisible(x)}
            layoutComponent={tooltipLayout}
          />
          <AppointmentForm
            messages={mesages}
            dateEditorComponent={DateEditor}
            visible={appointmentFormVisible}
            onVisibilityChange={(x) => setAppointmentFormVisible(x)}
            basicLayoutComponent={formLayout}
            commandLayoutComponent={commandLayout}
            textEditorComponent={textEditorComponent}
          />
          <Toolbar />
          <ViewSwitcher />
          <DateNavigator />
          <TodayButton buttonComponent={TodayButtonEdit} />
        </Scheduler>
      </Paper>
    );
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={3}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};
