import { Button, ButtonProps, styled } from "@material-ui/core";
import { connect } from "react-redux";
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
  GoogleLogout,
} from "react-google-login";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";

import { ButtonText } from "../dashboard-types";
import { useEffect, useState } from "react";
import { refreshTokenSetup } from "../../../utils/refreshTokenSetup";
import { RootState } from "../../../domain/booking/_redux/store/rootReducer";
import { actions } from "../../../domain/booking/_redux/store/user-store";
import { hasValue } from "../../../utils/object-utils";

export const mapStateToProps = (state: RootState) => state.users;
type Props = ReturnType<typeof mapStateToProps> & typeof actions;

export const LoginBase = (props: Props) => {
  const {
    token,
    setTokenRequest,
    clearUsers,
    getUserRequest,
    removeTokenRequest,
  } = props;

  const [buttonText, setButtonText] = useState<ButtonText>();
  const clientId =
    "676719608120-e60q1jjuqc5f26dvg6j4kv5mm8vlec19.apps.googleusercontent.com"; // brug denne
  //  "676719608120-fjo37aonlt5c2cfcg8hke4kqlseo1ntk.apps.googleusercontent.com";
  // "676719608120-mrpo2svum7l6cf9o9afkafeqvvug388q.apps.googleusercontent.com";

  const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: "white",
  }));

  const onsSuccess = (
    res: GoogleLoginResponse | GoogleLoginResponseOffline,
  ) => {
    if ("tokenId" in res) {
      refreshTokenSetup(res);
      setTokenRequest(
        res.tokenId,
        res.profileObj.email,
        res.profileObj.imageUrl,
      );
      getUserRequest(res.profileObj.email);
    }
  };

  const onFailure = (error: any) => {
    removeTokenRequest();
    clearUsers();
  };

  useEffect(() => {
    hasValue(token) ? setButtonText("Log ud") : setButtonText("Log ind");
  }, [token]);

  return (
    <>
      {buttonText === "Log ud" ? (
        <GoogleLogout
          className="dropdown-item"
          clientId={`${clientId}`}
          render={(renderProps) => (
            <ColorButton
              onClick={renderProps.onClick}
              startIcon={<LogoutIcon />}
            >
              {buttonText}
            </ColorButton>
          )}
          onLogoutSuccess={() => {
            removeTokenRequest();
            clearUsers();
          }}
        />
      ) : (
        <GoogleLogin
          clientId={`${clientId}`}
          render={(renderProps) => (
            <ColorButton
              onClick={renderProps.onClick}
              startIcon={<LoginIcon />}
            >
              {buttonText}
            </ColorButton>
          )}
          onSuccess={onsSuccess}
          onFailure={onFailure}
          cookiePolicy={"single_host_origin"}
          responseType="code,token"
          prompt="consent"
        />
      )}
    </>
  );
};

export const Login = connect(mapStateToProps, actions)(LoginBase);
