import { hasValue } from "./object-utils";
import { serializeDate } from "./serialize-utils";

type SimpleParam = string | number | boolean | Date;
export type Param = SimpleParam | null | undefined | Array<SimpleParam>;

export const buildUrl = (url: URL, params?: Record<string, Param>): string => {
  const serializeParam = (key: string, p: SimpleParam) => {
    if (p instanceof Date) return serializeDate(key, p);
    return p.toString();
  };
  if (hasValue(params)) {
    Object.keys(params).forEach(key => {
      const value = params[key];
      if (!hasValue(value)) return;
      return Array.isArray(value) ? value.forEach(v => url.searchParams.append(key, serializeParam(key, v))) : url.searchParams.append(key, serializeParam(key, value));
    });
  }
  return url.toString();
};
